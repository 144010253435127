export default [`报名须知
`, `
1. 本次比赛由浙江大学CC98论坛主办。
2. 报名时须使用CC98论坛帐号，并填写雀魂昵称、雀魂数字ID、QQ、手机号四项资料。请保证资料的真实性，相关资料只会在验证参赛资格、发送比赛通知、赛后奖品发放联络时使用，我们会妥善保管相关资料。QQ和手机号不会被公开。
3. 每个人仅限使用与本人浙大通行证绑定的CC98论坛帐号报名，只能使用一个帐号报名。请勿重复报名，请勿外借CC98帐号给他人报名。
4. 报名后将进行简单的审核，审核通过后才为报名成功。对审核不通过有异议的，请联系 contact@cc98.org。
5. 报名将在11月16日12:00:00（北京时间，下同）或报名成功人数达到256人时结束，取两者较早的时间。
6. 本次比赛娱乐为主，友谊第一，比赛第二。
7. 部分场次比赛可能会在B站直播，直播间 https://live.bilibili.com/5652521。
8. 报名即视为同意本页面全部内容，CC98运营管理团队对本次比赛拥有最终解释权。
9. 本次比赛奖品中的雀魂游戏道具和相关周边由雀魂麻将官方提供，在此表示感谢。

`,
    `比赛规则
`, `
1. 本次比赛为个人赛，分为海选赛和淘汰赛两个阶段。比赛在雀魂麻将（https://www.maj-soul.com/）的比赛场进行。每场比赛使用四人半庄、5+20秒、起始点数25000、3赤、有食断、有便捷提示、无击飞、无西入、无和了终局、无听牌终局的设置，其余规则按比赛场的默认设置。
2. 海选赛阶段，每人至少参加4场，最多参加10场。比赛时间为11月17日12:00:00至11月19日23:59:59（按开场时间算）。海选赛阶段可在比赛场、比赛群内随时约人开赛。
3. 海选赛结束后，按最佳连续4战合计精算分进行排名，前64名进入淘汰赛阶段。精算分相同的，按该4战中最高得点较高的排前；最高得点也相同则比较次高得点，直至比到最低得点；若仍相同，抽签决定先后顺序。
4. 淘汰赛分为16组，分组时，海选赛前16名作为种子选手，分到不同的16个比赛组里，再随机分配剩下48名。淘汰赛的比赛按照赛程表进行。每场淘汰赛进行2个半庄，按精算分之和排序。精算分相同的，按海选赛排名较高的排前。每场淘汰赛前两名进入下一轮，直至比出前四名。淘汰赛在11月25-26日、12月2-3日两个周末进行。
5. 淘汰赛阶段，如果选手在约定的比赛时间20分钟之后仍未出现，视为缺席并放弃参加该场比赛，缺席的选手不晋级下一轮。当只有一名选手缺席时，安排工作人员代打。当出现二名及以上选手缺席时，由主办方根据实际情况另行安排。
6. 比赛时建议在稳定的网络及合适的环境下进行，出现掉线等情况不安排重赛。
7. 比赛过程中如发现违反报名须知第3条或者出现违反体育精神行为的，将取消相关选手成绩、不发放相应奖品。
8. 出现其他意外情况时由主办方根据实际情况进行安排。

`, `比赛奖品
`, `
（辉玉、月势御守和铜币指雀魂游戏内道具，威望指CC98论坛帐号的威望）
1. 冠军：4000辉玉+月势御守(30天)+冠军周边套装【帆布袋*1+新款鼠标垫*1（随机）+名台词软胶挂件*4 （随机）+立绘徽章*4（随机）】+398元现金+5威望
2. 亚军：3000辉玉+月势御守(30天)+亚军周边套装【帆布袋*1+新款鼠标垫*1（随机）+名台词软胶挂件*3（随机）+立绘徽章*3（随机）】+198元现金+3威望
3. 季军：1600辉玉+月势御守(30天)+季军周边套装【帆布袋*1+新款鼠标垫*1（随机）+名台词软胶挂件*2（随机）+立绘徽章*2（随机）】+198元现金+3威望
4. 殿军：1000辉玉+月势御守(30天)+殿军周边套装【帆布袋*1+新款鼠标垫*1（随机）+名台词软胶挂件*1（随机）+立绘徽章*1（随机）】+198元现金+3威望
5. 第5-8名：600辉玉+月势御守(30天)+名台词软胶挂件*1（随机）+立绘徽章*1（随机）+2威望
6. 第9-16名：300辉玉+月势御守(30天)+立绘徽章*1（随机）+2威望
7. 第17-32名：8万铜币+2威望
8. 第33-64名：5万铜币+2威望
9. 65名及以后（参加至少四场）：1威望
`]
